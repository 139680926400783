@import url("https://unpkg.com/tailwindcss@1.9.6/dist/tailwind.min.css");

.login-page {
  /* background: #ffffff !important; */
  background: linear-gradient(
    to bottom,
    #242f65,
    #242f65 16.7%,
    #62336b 100%
  ) !important;
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
  /* sm */
  @media (min-width: 640px) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  /* lg */
  @media (min-width: 1024px) {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
.login-box,
.register-box {
  width: 450px;
  margin-top: 0.8rem;
}

.login-box-msg {
  margin-top: 0.9rem;
  margin-bottom: 0.6rem;
  color: #bdcff1;
  font-size: 14px;
}
.login-box-msg a,
.login-box-msg a:hover {
  text-decoration: none;
  border-bottom: 1px solid currentColor;
}
.login-box-msg a {
  margin-left: 0.4rem;
  color: #d5dff5;
}
.login-box-msg a:hover {
  color: #ffffff;
}
.login-logo,
.register-logo {
  margin-bottom: 0px;
  font: 36px;
  @media (min-width: 640px) {
    max-width: 44.8rem;
    width: 100%;
  }
}
.logo-img {
  height: 2rem;
  width: 11.2rem;
  @media (min-width: 640px) {
    height: 2.8rem;
    width: 12.8rem;
  }
}

.logo-text {
  margin-top: 2.4rem;
  font-size: 2.4rem;
  line-height: 3.2rem;
  @media (min-width: 768px) {
    font-size: 3rem;
    line-height: 3.6rem;
  }
  @media (min-width: 1280px) {
    font-size: 3.6rem;
    line-height: 4rem;
  }
}
.login-box-body {
  border-radius: 0.8rem;
  padding: 3.2rem 1.6rem;
  margin-top: 0.8rem;
  @media (min-width: 640px) {
    padding-left: 4rem;
    padding-right: 4rem;
    max-width: 44.8rem;
    width: 100%;
    margin: 0 auto;
    margin-top: 0.8rem;
  }
}

.login-box-body .content-header {
  padding: 0px;
}

.login-box-body .alert {
  width: 100%;
  font-weight: bold;
}

.login-box-body .form-control-feedback,
.register-box-body .form-control-feedback {
  color: #545454;
}

.session-page-heading {
  font-weight: 300;
  font-size: 50px;
  color: #ffffff;
}
.login-form-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 100%;
}
.login-form-input:not(:first-of-type) {
  margin-top: 0.8rem;
}
.login-form-input-label {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #4e3751;
}
.success-message {
  font-size: 1.6rem;
  line-height: 2rem;
  color: #4e3751;
}
.success-message:last-of-type {
  margin-bottom: 1.6rem;
}
.highlighted {
  font-size: 2.2rem;
  font-weight: 900;
  line-height: 2.6rem;
}
p.form-label {
  font-weight: bold;
  color: #545454;
}
.login-form-input-spacing {
  margin-top: 0.4rem;
}
.login-form-input-field {
  background-image: none;
  border-radius: 0.6rem;
  border: 1px solid #dad1db;
  box-sizing: border-box;
  color: #545454;
  display: inline-block;
  font-size: inherit;
  outline: none;
  padding: 0.8rem 1.2rem;
  /* transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1); */
  width: 100%;

  @media (min-width: 640px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
.login-form-input-field:focus {
  border-color: #5175d1;
  box-shadow: 0 0 0 1px #5175d1;
  outline: none;
}
.login-form-input-field::placeholder {
  color: #ad9caf;
}

.checkbox.iCheck {
  margin-top: 0px;
}
.checkbox {
  display: flex;
  align-items: center;
}
.check-box-input {
  height: 1.6rem;
  width: 1.6rem;
  color: #415cc0;
  border-radius: 0.6rem;
  margin: 0 !important;
}

.checkbox-text {
  margin-left: 2.4rem;
  color: #261127;
  font-size: 1.4rem;
  line-height: 2rem;
}
.forget-password a {
  text-decoration: none;
  color: #415cc0;
}
.forget-password a:hover {
  text-decoration: none;
  color: #5175d1;
}
.form-controls.sign-in-btn {
  margin-top: 1.5em;
}
.form-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.6rem 0;
}
.btn-next {
  background: #f25056;
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  margin-top: 0.8rem;
  border: 1px solid transparent;
  outline: none;
}
a.btn-next:hover {
  text-decoration: none;
}
.btn-next:hover {
  background: #fa7e82;
}
.btn-next:focus {
  box-shadow: 0 0 0 4px rgba(224, 49, 55, 0.5);
  outline: none;
}
.fa-arrow-right {
  margin: 0 0 0 0.8rem;
  font-size: 1.8rem;
  height: 1.8rem;
  width: 1.8rem;
}
.group {
  padding: 0.2rem;
  background-color: #ebe5eb;
}
.group button {
  padding: 0.6rem 1.4rem 0.6rem 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #604b63;
  display: flex;
  align-items: center;
  justify-content: center;
}
#join-account,
#create-account {
  border-radius: 9999px !important;
  display: flex;
  align-items: center;
}
.group button:not(:first-of-type) {
  margin-left: 0.2rem;
}
.group button i {
  margin-right: 0.8rem;
  font-size: 2.4rem;
}
.center-div {
  display: block;
  margin: auto;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
  width: min-content;
}
.type-icon.tab-selected-icon {
  color: #5175d1;
}
.type-text.tab-selected-text {
  color: #261127;
}
.type-icon {
  color: #7f6b80;
}
.type-text {
  color: #604b63;
}
.icon-plane {
  margin: 4rem 0;
  font-size: 4rem;
  color: #242f65;
}
.term-policy {
  color: #7f6b80;
  font-size: 1.4rem;
  line-height: 2rem;
}
.term-policy a {
  font-weight: 500;
  text-decoration: underline;
  margin: 0 1%;
}
.term-policy a:last-of-type {
  margin: 0 0 0 1%;
}
.font-display {
  font-family: font-bobby;
}
.font-jan {
  font-family: font-jan;
}
.font-jan-b {
  font-family: font-jan-b;
}

.logo-img__modal {
  height: 1.5rem;
}
#site-switch-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.site-switch-modal {
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 50%;
}

.site-switch-modal-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #212f69;
}
.site-switch-modal-cta {
  background: #212f69;
  width: 40%;
  border-radius: 5px;
}
@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.flash-success {
  color: #065f4c;
  background-color: #ecfdf6;
  padding: 1.6rem;
  border-left: 4px solid #34d3a4;
  font-weight: 400 !important;
}
.flash-danger {
  color: #a81b20;
  background-color: #fef4f2;
  padding: 1.6rem;
  border-left: 4px solid #fa7e82;
  font-weight: 400 !important;
}
.flash-info {
  color: #3b4583;
  background-color: #e5ecfa;
  padding: 1.6rem;
  border-left: 4px solid #6f95dc;
  font-weight: 400 !important;
}
.flash-warning {
  color: #af461a;
  background-color: #fff9eb;
  padding: 1.6rem;
  border-left: 4px solid #f4ba53;
  font-weight: 400 !important;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

@font-face {
  font-family: "font-bobby";
  src: url("../../fonts/F37Bobby-Regular.woff") format("woff");
}
@font-face {
  font-family: "font-jan";
  src: url("../../fonts/F37Jan-Regular.woff") format("woff");
}
@font-face {
  font-family: "font-jan-b";
  src: url("../../fonts/F37Jan-Bold.woff") format("woff");
}
